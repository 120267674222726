import { ChangeEvent, ReactNode } from "react";

export default function SearchInput({
  type = "text",
  placeholder,
  value,
  onChange,
}: {
  placeholder: string;
  value: string;
  type?: "text" | "password" | "email" | "url" | "number";
  onChange: (a: ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <div
      className={
        "h-[47px] lg:w-full w-[95%] overflow-hidden flex items-center border rounded-lg  lg:border-primary-blue"
      }
    >
      <input
        type={type}
        value={value}
        onChange={onChange}
        className="bg-gray-50 w-full h-full  text-gray-900 text-sm  focus:outline-0 block p-2.5"
        placeholder={placeholder}
      />
      <div
        className={
          "w-[68px] lg:grid hidden place-content-center h-full bg-primary-blue"
        }
      >
        <svg
          className="w-4 h-4 text-gray-500 dark:text-gray-400"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
          />
        </svg>
      </div>
    </div>
  );
}
