import { ReactNode } from "react";

export default function FollowButton({
  isFollowing = false,
  onClick,
  className,
}: {
  onClick?: () => void;
  className?: string;
  isFollowing?: boolean;
}) {
  return (
    <button
      type="button"
      onClick={onClick ? onClick : () => null}
      className={`${className ? className : ""} w-[92px] ${isFollowing ? "text-secondary-dark border-secondary-dark" : "text-primary-blue border-primary-blue"} border bg-transparent font-medium rounded-lg text-sm h-[35px] focus:outline-none`}
    >
      {isFollowing ? "Following" : "Follow"}
    </button>
  );
}
