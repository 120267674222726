"use client";
import styles from "./dashboard.module.scss";
import Image from "next/image";
import CoinCard from "@/components/CoinCard";
import FollowButton from "@/components/FollowButton";
import EmptyTabsPlaceholder from "@/components/EmptyTabsPlaceholder";
import SearchInput from "@/components/SearchInput";
import Toggle from "@/components/Toggle";
import DropMenu from "@/components/DropMenu";
import { useEffect, useRef, useState } from "react";
import Dropdown from "@/components/Dropdown";
import Tooltip from "@/components/Tooltip";
import { getRocketsInFLight, getSortedTokens } from "@/network/token";
import {
  dataWithHasMoreData,
  KeyValueObjectType,
  KeyValueStringType,
  leaderBoardItemType,
  tokensResponse,
  tokenType,
  userType,
} from "@/types/global";
import { EmptyTabsImageENUM, paginationConfigType } from "@/types/component";
import useSocket from "@/customHooks/useSocket";
import { useAppSelector } from "@/redux/hooks";
import { followUser, getUserLeaderboard, unFollowUser } from "@/network/user";
import FilterOptions from "@/components/FilterOptions";
import Loading from "@/components/Loading";
import { formatNumberWithCommas, getImageUrl } from "@/utils/helper";
import { useRouter } from "next/navigation";
import Pagination from "@/components/Pagination";
import { Images } from "@/utils/imageRelativePaths";

const TokenSortOptions = [
  { label: "Bump Order", key: "bump_order" },
  { label: "Market cap - High to Low", key: "mc_h2l" },
  { label: "Market cap - Low to High", key: "mc_l2h" },
  { label: "Creation Time", key: "time" },
];
const AUTO_REFRESH_SUPPORTED = ["bump_order", "time"];
const TokenFilterOptions = [
  { label: "Total Market Cap ($)", key: "marketCap" },
  { label: "No. of Holders", key: "holdersCount" },
  { label: "No. of Transactions", key: "transactionsCount1H" },
];

interface filterDataType {
  [p: string]: string;
}
const Dashboard = () => {
  const router = useRouter();
  const socket = useSocket();
  const filterRef = useRef(null);
  const [loading, setLoading] = useState({
    rockets: true,
    winner: true,
    tokens: true,
  });
  const ETH_PRICE = useAppSelector(
    (state) => state.Config.config?.ETH_PRICE ?? 0,
  );
  const siteKey = useAppSelector((state) => state.Config.SITE_KEY);
  const isCeresSite = siteKey === "ceres";
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const token = useAppSelector((state) => state.Auth.token);
  const [tokens, setTokens] = useState<tokenType[]>([]);
  const [leaderboard, setLeaderBoard] = useState<leaderBoardItemType[]>([]);
  const [rockets, setRockets] = useState<tokenType[]>([]);
  const [sortOrder, setSortOrder] = useState("bump_order");
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [onUniSwap, setOnUniSwap] = useState(false);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState<
    paginationConfigType | undefined
  >(undefined);
  const nextCursor = tokens[tokens.length - 1]?.paginationToken;
  const prevCursor = tokens[0]?.paginationToken;
  let isMobile = false;
  if (typeof window !== "undefined") {
    isMobile = window.innerWidth < 600;
  }

  const [filterData, setFilterData] = useState<filterDataType>({});
  useEffect(() => {
    fetchData();
  }, [token]);

  const fetchSortedTokens = async () => {
    // setLoading((r) => {
    //   return { ...r, rockets: true };
    // });
    const res = await getRocketsInFLight();
    if (res && Array.isArray(res)) {
      setRockets(res.slice(0, 3));
    }
    setLoading((r) => {
      return { ...r, rockets: false };
    });
  };
  const fetchData = () => {
    fetchSortedTokens();
    getUserLeaderboard().then((res) => {
      if (Array.isArray(res)) {
        setLeaderBoard(res);
        setLoading((r) => {
          return { ...r, winner: false };
        });
      }
    });
  };
  useEffect(() => {
    if (!socket) return;
    socket.on("bump_order", (data: tokenType) => {
      console.log("bump_order", data);
      if (autoRefresh) {
        setTokens((r) => {
          const updates = r.filter((item) => item._id !== data._id);
          updates.unshift(data);
          return updates;
        });
      }
    });
    socket.on("token_created", (data: tokenType) => {
      console.log("token_created", data);
      // append token only if page is one
      if (autoRefresh && page === 1) {
        setTokens((r) => {
          return [data, ...r];
        });
      }
    });
  }, [socket]);

  const onAutoRefreshChange = (a: boolean) => {
    setAutoRefresh(a);
  };

  // call API if sort order is changed
  useEffect(() => {
    // get min max values from filter data
    let query = getFilterQuery();
    getSortedTokens(sortOrder, search, pagination, query).then((res) => {
      setHasMore(
        pagination?.action === "prev"
          ? true
          : (!!res && res?.hasMoreData) || false,
      );
      setTokens(res ? res.tokens : []);
      setLoading((r) => {
        return { ...r, tokens: false };
      });
    });
  }, [sortOrder, token, pagination]);

  // call API if search query is changed
  useEffect(() => {
    let timer;
    timer = setTimeout(async () => {
      const query = getFilterQuery();
      const tokensResponse = await getSortedTokens(
        sortOrder,
        search,
        pagination,
        query,
      );
      if (tokensResponse) {
        setTokens(tokensResponse.tokens);
        setHasMore(tokensResponse.hasMoreData);
      }
      setLoading((r) => {
        return { ...r, tokens: false };
      });
    }, 500);
    return () => clearTimeout(timer);
  }, [search]);

  const onPaginationChange = (action: "prev" | "next") => {
    if (action === "next") {
      if (hasMore) {
        setLoading((r) => {
          return { ...r, tokens: true };
        });
        setPage((p) => p + 1);
        setPagination({ prev: prevCursor, next: nextCursor, action });
      }
      return;
    }

    //prev
    if (page > 1) {
      setLoading((r) => {
        return { ...r, tokens: true };
      });
      setPage((p) => p - 1);
      setPagination({ prev: prevCursor, next: nextCursor, action });
    }
  };

  const handleSortOrderClick = (a: string) => {
    setSortOrder(a);
    setPage(1);
    setPagination(undefined);
    setAutoRefresh(AUTO_REFRESH_SUPPORTED.includes(a));
  };

  const handleFollowUser = async (currUser: userType) => {
    const isFollowing = currUser.isFollowing;
    const id = currUser._id;
    const response = isFollowing
      ? await unFollowUser(id as string)
      : await followUser(id as string);
    if (response === "OK") {
      setLeaderBoard((r) =>
        r.map((item) => {
          if (item._id === id) {
            item.isFollowing = !isFollowing;
          }
          return item;
        }),
      );
    }
  };

  // Set Filter options --->>

  const onFilterOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFilterData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const getEthVal = (price: number | string) => {
    return (parseFloat(price.toString()) / ETH_PRICE).toFixed(2);
  };
  const getFilterQuery = () => {
    if (!filterData || typeof filterData !== "object") return "";
    let query = "";
    const queryObj: KeyValueObjectType = {};
    Object.entries(filterData as KeyValueStringType).forEach(([key, value]) => {
      if (typeof value !== undefined) {
        const [valueKey, valueType] = key.split("_");
        if (!queryObj[valueKey]) {
          queryObj[valueKey] = {};
        }
        // for market cap filter with send ETH in filter
        queryObj[valueKey][valueType] =
          valueKey === "marketCap" && !isNaN(parseFloat(value))
            ? getEthVal(value)
            : value;
      }
    });
    Object.entries(queryObj).forEach(([key, value]) => {
      if (typeof value === "object" && Object.keys(value).length === 0) return;
      const { min, max } = value;
      // skip empty values
      if (min?.length || max?.length) {
        query =
          query +
          `${query.length ? "&" : ""}${key}={${min ? '"$gt":' + min : ""}` +
          `${min && max ? "," : ""}` +
          `${max ? '"$lt":' + max : ""}}`;
      }
    });

    // uniswap
    if (onUniSwap) {
      query = query + `${query.length ? "&" : ""}tradingOnUniswap=${onUniSwap}`;
    }
    return query;
  };

  const onFilterApply = () => {
    if (!filterData) return;
    setLoading((r) => {
      return { ...r, tokens: true };
    });
    // get min max values from filter data
    let query = getFilterQuery();
    getSortedTokens(sortOrder, search, pagination, query).then((res) => {
      setTokens(res ? res.tokens : []);
      setHasMore(res ? res.hasMoreData : false);
      setLoading((r) => {
        return { ...r, tokens: false };
      });
    });
  };

  const WINNERS_CIRCLE = (
    <div className={styles.winners}>
      <div className={styles.sectionHeading}>
        <Image
          alt={"verified"}
          src={"/images/icons/winner.svg"}
          width={24}
          height={24}
        />
        <p
          className={"text-primary-dark leading-6 mx-2 text-2xl font-semibold"}
        >
          {isCeresSite ? "Top Guns" : "Winners Circle"}
        </p>
        <Tooltip
          position={"bottom"}
          content={"Most Profitable traders in the last 4 hours"}
        >
          <Image
            alt={"info"}
            src={"/images/icons/info.svg"}
            width={24}
            height={24}
          />
        </Tooltip>
      </div>
      <div className={styles.winnersCont}>
        {loading.winner ? (
          <Loading size={"md"} />
        ) : (
          <>
            {leaderboard.map((item, index) => (
              <div
                className={`${styles.eachWinner} py-0.5 w-full flex items-center justify-between mt-2`}
                key={index}
              >
                <Image
                  src={
                    getImageUrl(item.profilePic) ??
                    (Images.FOMO_CLICK_LOGO as string)
                  }
                  className={"w-[32px] h-[32px] rounded-[50%]"}
                  alt={"user"}
                  width={32}
                  height={32}
                />
                <p
                  className={
                    "text-xs hover:underline cursor-pointer font-semibold text-left flex-1 ml-2 text-[#0C092A]"
                  }
                  onClick={() => {
                    router.push("/profile/" + item.username);
                  }}
                >
                  @{item.username}
                </p>
                <p
                  className={`text-xs font-medium text-center flex-1 ml-2 ${item.profitPercentage >= 0 ? "text-change-positive" : "text-change-negative"}`}
                >
                  <span className={"mr-2 text-base"}>💸</span>
                  {item.profitPercentage > 0 ? "+" : "-"}
                  {item.profitPercentage.toFixed(2)}%
                </p>
                <FollowButton
                  isFollowing={item.isFollowing}
                  onClick={() => handleFollowUser(item)}
                />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
  return (
    <div className={styles.dashboardCont}>
      <div className={`${styles.leaderBoardCont}`}>
        <div className={styles.rockets}>
          <div className={styles.sectionHeading}>
            <Image
              alt={"verified"}
              src={"/images/icons/check-verified.svg"}
              width={24}
              height={24}
            />
            <p
              className={
                "text-primary-dark leading-6 mx-2 text-2xl font-semibold"
              }
            >
              {isCeresSite ? "High Flyers" : "Rockets in flight"}
            </p>
            <Tooltip
              content={`Tokens which have recently crossed $${formatNumberWithCommas(parseInt("" + 6 * ETH_PRICE))} market cap`}
            >
              <Image
                alt={"info"}
                src={"/images/icons/info.svg"}
                width={24}
                height={24}
              />
            </Tooltip>
          </div>
          <div className={styles.cardsContainer}>
            {loading.rockets ? (
              <Loading
                size={"lg"}
                className={
                  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                }
              />
            ) : (
              <>
                {rockets.length > 0 ? (
                  rockets.map((item, index) => (
                    <CoinCard
                      type={isMobile ? "rockets" : "card"}
                      className={styles.eachTokenCard}
                      key={item._id}
                      token={item}
                    />
                  ))
                ) : (
                  <div className={styles.placeholderCont}>
                    <EmptyTabsPlaceholder
                      props={{
                        Icon: {
                          link: `${EmptyTabsImageENUM.NO_ROCKETS}`,
                          height: 100,
                          width: 100,
                        },
                        description: "No tokens in rockets in flight",
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {WINNERS_CIRCLE}
      </div>
      <div className={styles.allCoinsCont}>
        <div className={`${styles.coinsHeadingCont}`}>
          <div className={"lg:w-2/3 w-3/5 flex items-center justify-between"}>
            <p
              className={
                "w-[130px] text-primary-dark leading-6 mr-2 text-2xl font-semibold"
              }
            >
              All Tokens
            </p>
            <SearchInput
              placeholder={"Search by token name, ticker and address"}
              value={search}
              onChange={(e) => {
                if (e.target.value.length > 2) {
                  setLoading((r) => {
                    return { ...r, tokens: true };
                  });
                }
                setPage(1);
                setSearch(e.target.value);
              }}
            />
          </div>
          <div className={"lg:w-1/3 w-2/5 flex  items-center justify-end"}>
            {AUTO_REFRESH_SUPPORTED.includes(sortOrder) ? (
              <Toggle
                className={styles.toggle}
                checked={autoRefresh}
                onChange={onAutoRefreshChange}
                label={"Auto Refresh"}
              />
            ) : (
              ""
            )}
            <Dropdown
              parentContainer={<DropMenu title={"Sort By"} options={[]} />}
              className="right-0"
            >
              <div className="">
                {TokenSortOptions.map((item, index) => (
                  <>
                    <span
                      onClick={() => handleSortOrderClick(item.key)}
                      className={`flex pl-4 py-2 pr-24 gap-2 cursor-pointer ${sortOrder === item.key ? "bg-primary-blue text-white" : "text-secondary-Black"}`}
                      key={item.key}
                    >
                      <span className="text-xs font-normal whitespace-nowrap ">
                        {item.label}
                      </span>
                    </span>
                    <hr />
                  </>
                ))}
              </div>
            </Dropdown>
            <Dropdown
              closeOnParentClick={false}
              parentContainer={<FilterOptions />}
              className="right-0"
            >
              <div className=" flex flex-col justify-center items-center py-3 px-2.5 rounded-[10px] bg-white ">
                {TokenFilterOptions.map((cElement) => {
                  return (
                    <div className=" py-[14px] px-[16px] border-b border-[#939FB81F]">
                      <span className="text-sm font-normal text-secondary-Black">
                        {cElement.label}
                      </span>
                      <div className=" flex items-center gap-[27px] mt-[8px]">
                        <input
                          type="number"
                          className="text-center text-sm bg-white rounded-[4px] px-1 border border-secondary-dark text-[10px] font-medium text-primary-gray [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none outline-none	w-[50px] h-[24px] placeholder-primary-gray placeholder-opacity-70 "
                          placeholder="Min"
                          name={`${cElement.key}_min`}
                          value={
                            filterData[
                              `${cElement}_min` as keyof filterDataType
                            ]
                          }
                          onChange={onFilterOptionChange}
                        />
                        <span className="text-secondary-Black">-</span>
                        <input
                          type="number"
                          className="text-center text-sm bg-white rounded-[4px] px-1 border border-secondary-dark text-[10px] font-medium text-primary-gray [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none outline-none	w-[50px] h-[24px] placeholder-primary-gray placeholder-opacity-70"
                          placeholder="Max"
                          name={`${cElement.key}_max`}
                          value={
                            filterData?.[
                              `${cElement.key}_max` as keyof filterDataType
                            ]
                          }
                          onChange={onFilterOptionChange}
                        />
                      </div>
                    </div>
                  );
                })}

                <div className=" flex my-[14px] gap-4 items-center w-full pl-4">
                  <input
                    type="checkbox"
                    name="onUniswap"
                    className="h-[20px] w-[20px] dark:bg-white bg-white cursor-pointer"
                    onChange={(e) => setOnUniSwap(e.target.checked)}
                    checked={onUniSwap}
                  />
                  <label className="text-xs font-normal text-secondary-Black">
                    On Uniswap
                  </label>
                </div>

                <div
                  onClick={onFilterApply}
                  className="text-base font-bold bg-primary-blue text-white py-2 px-6 rounded-[6px] cursor-pointer"
                >
                  Apply
                </div>
              </div>
            </Dropdown>
          </div>
        </div>
        {loading.tokens ? (
          <Loading size={"lg"} />
        ) : (
          <>
            {tokens.length === 0 ? (
              <div className={styles.placeholderCont}>
                <EmptyTabsPlaceholder
                  props={{
                    Icon: {
                      link: `${EmptyTabsImageENUM.NO_COIN}`,
                      height: 100,
                      width: 100,
                    },
                    description: "No Tokens found as per your search",
                  }}
                />
              </div>
            ) : (
              <>
                <div className={styles.allCoinsCards}>
                  {tokens.map((item, index) => (
                    <CoinCard key={item._id} token={item} />
                  ))}
                </div>
              </>
            )}
            <div className={"my-4 w-full grid place-items-center"}>
              {(page == 1 && tokens.length > 0) || page > 1 ? (
                <Pagination
                  page={page}
                  disableNext={!hasMore}
                  onNext={() => {
                    onPaginationChange("next");
                  }}
                  onPrevious={() => {
                    onPaginationChange("prev");
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </>
        )}
      </div>
      <div className={styles.winnersMobile}>{WINNERS_CIRCLE}</div>
    </div>
  );
};

export default Dashboard;
