export default function Pagination({
  page,
  disableNext,
  onNext,
  onPrevious,
}: {
  page: number;
  disableNext: boolean;
  onNext: () => void;
  onPrevious: () => void;
}) {
  return (
    <div className="flex items-center gap-2">
      <div
        onClick={onPrevious}
        className={`flex items-center justify-center px-3 h-8 text-sm font-medium text-primary-blue bg-white border border-primary-blue rounded-lg hover:bg-primary-blue hover:text-white ${page === 1 ? "cursor-not-allowed" : "cursor-pointer"}`}
      >
        <svg
          className="w-3.5 h-3.5 me-2 rtl:rotate-180"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 10"
        >
          <path
            stroke="currentColor"
            strokeLinecap={"round"}
            strokeLinejoin={"round"}
            strokeWidth={2}
            d="M13 5H1m0 0 4 4M1 5l4-4"
          />
        </svg>
        Previous
      </div>
      <div className="flex items-center justify-center px-3 h-8 leading-tight text-primary-blue bg-white border rounded-lg border-primary-blue hover:bg-primary-blue hover:text-white">
        {page}
      </div>
      <div
        onClick={disableNext ? () => {} : onNext}
        className={`flex items-center justify-center px-3 h-8 text-sm font-medium text-primary-blue bg-white border border-primary-blue rounded-lg hover:bg-primary-blue hover:text-white ${disableNext ? "cursor-not-allowed" : "cursor-pointer"}`}
      >
        Next
        <svg
          className="w-3.5 h-3.5 ms-2 rtl:rotate-180"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 10"
        >
          <path
            stroke="currentColor"
            strokeLinecap={"round"}
            strokeLinejoin={"round"}
            strokeWidth={2}
            d="M1 5h12m0 0L9 1m4 4L9 9"
          />
        </svg>
      </div>
    </div>
  );
}
