import React, { ReactNode } from "react";
import styles from "./coincard.module.scss";
import Image from "next/image";
import ProgressBar from "@/components/ProgressBar";
import SVG from "react-inlinesvg";
import {
  GLOBE_ICON,
  Images,
  TELEGRAM_ICON,
  X_ICON_WHITE,
} from "@/utils/imageRelativePaths";
import { useRouter } from "next/navigation";
import { tokenType } from "@/types/global";
import {
  formatDecimals,
  formatDescription,
  formatNumberWithLetters,
  getBondingCurveProgress,
  getImageUrl,
  openExternalUrl,
} from "@/utils/helper";
import userOpenLink from "@/customHooks/userOpenLink";

const SOCIAL_LINKS = [
  { key: "twitter", icon: X_ICON_WHITE },
  { key: "telegram", icon: TELEGRAM_ICON },
  { key: "website", icon: GLOBE_ICON },
];

export default function CoinCard({
  token,
  onClick,
  className,
  type = "card",
}: {
  token: tokenType;
  onClick?: () => void;
  className?: string;
  type?: string;
}) {
  if (!token) return null;
  const router = useRouter();
  const { openLink } = userOpenLink();
  const onSocialIconClick = async (
    e: React.MouseEvent<HTMLDivElement>,
    key: string,
  ) => {
    const socialKey = key as keyof typeof token.social;
    const socialLink = token.social ? token.social[socialKey] : undefined;
    openLink(socialLink ?? "", key as keyof typeof token.social);
    e.stopPropagation();
  };
  const checkSocialLink = (key: string) => {
    if (!token.social) return false;
    const socialKey = key as keyof typeof token.social;
    const socialLink = token.social ? token.social[socialKey] : undefined;
    return !!socialLink;
  };
  const isRocket = type === "rockets";
  return (
    <div
      onClick={onClick ? onClick : () => router.push(`/token/${token.mint}`)}
      className={`${styles.cardCont} ${className ? className : ""}`}
    >
      <div
        className={`${styles.imgCont} ${isRocket ? styles.rocketImgCont : ""}`}
      >
        <Image
          src={getImageUrl(token.image) ?? (Images.FOMO_CLICK_IMG as string)}
          alt={"token"}
          width={168}
          height={57}
        />
        <div className={styles.percentageCont}>
          <Image
            src={
              (token.meta?.priceChange1H ?? 0) >= 0
                ? Images.PRICE_UP
                : Images.PRICE_DOWN
            }
            alt={"up"}
            width={14}
            height={14}
          />
          <span>{formatDecimals("" + token.meta?.priceChange1H ?? 0, 2)}%</span>
        </div>
      </div>
      {isRocket ? (
        <div className={"flex items-center pb-2 flex-col"}>
          <p className={"text-sm font-bold text-primary-dark"}>{token.name} </p>
          <span className={"text-primary-blue text-xs font-semibold"}>
            {"($" + token.ticker + ")"}
          </span>
        </div>
      ) : (
        <div className={"p-4 flex flex-col h-full justify-between"}>
          <div
            className={
              "flex items-center flex-wrap text-sm md:text-xl font-bold text-primary-dark"
            }
          >
            {token.name}{" "}
            <span
              className={
                "ml-1 text-primary-blue font-semibold text-xs md:text-sm"
              }
            >
              {"($" + token.ticker + ")"}
            </span>
            {token && token?.tax > 0 ? (
              <Image
                className={"ml-2"}
                src={Images.TAX_ICON}
                alt={"tax"}
                width={16}
                height={16}
              />
            ) : (
              ""
            )}
          </div>
          <div className={`flex items-center ${styles.followersCont}`}>
            <p
              className={
                "md:text-[11px] text-[10px] font-normal text-[#41485C]"
              }
            >
              Followers :{" "}
              <span className={"text-[#FB923C]"}>
                {formatNumberWithLetters(token.bookmarksCount, 0)}
              </span>
            </p>
            <div className="flex items-center ml-2 gap-1">
              {SOCIAL_LINKS.map(
                (socialIcon, index) =>
                  checkSocialLink(socialIcon.key) && (
                    <div
                      key={index}
                      onClick={(e) => onSocialIconClick(e, socialIcon.key)}
                      className={`border p-1 border-[#939FB83D] rounded-full bg-[#F6F7F8] cursor-pointer ${styles.iconCont} ${index === 0 ? styles.twitter : styles.other}`}
                    >
                      <SVG
                        src={socialIcon.icon}
                        width={index === 0 ? 14 : 16}
                        height={index === 0 ? 14 : 16}
                      />{" "}
                    </div>
                  ),
              )}
            </div>
          </div>

          <p
            className={`${token?.name?.length > 14 ? styles.description1 : styles.description2} text-primary-dark leading-5 md:text-[14px] text-[13px]`}
          >
            {token.description ?? ""}
          </p>
          <p
            className={"md:text-[11px] text-[10px] font-normal text-[#41485C]"}
          >
            Market Cap:{" "}
            <span className={"font-bold"}>
              $
              {formatNumberWithLetters(
                parseFloat(token?.meta?.marketCapUsd ?? "0"),
              )}
            </span>
          </p>
          {token.meta?.tradingOnUniswap ? (
            <p className={"w-full text-xs text-primary-dark"}>
              Listed on{" "}
              <span className={"text-primary-blue font-semibold"}>Uniswap</span>
            </p>
          ) : (
            <ProgressBar
              height={1}
              progressWidth={getBondingCurveProgress(
                parseFloat(token.meta?.marketCap ?? "0"),
                token,
              )}
              width={"full"}
            />
          )}
        </div>
      )}
    </div>
  );
}
