import { singleItem } from "@/types/global";
import { FILTER_ICON } from "@/utils/imageRelativePaths";
import Image from "next/image";

export default function FilterOptions() {
  return (
    <div className={"relative ml-2 md:ml-4 cursor-pointer"}>
      <div className="select-none justify-evenly flex items-center rounded-lg bg-[#939FB81F] py-3 h-[48px] px-4 text-center text-[#51586E] align-middle text-base font-bold transition-all hover:shadow-md hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none">
        <Image
          src={FILTER_ICON}
          alt="Filter"
          height={22}
          width={22}
          className={"w-[16px] h-[16px] md:w-[22px] md:h-[22px]"}
        />
      </div>
    </div>
  );
}
