export default function Toggle({
  label,
  checked,
  onChange,
  className = "",
}: {
  label: string;
  checked: boolean;
  className: string;
  onChange: (a: boolean) => void;
}) {
  return (
    <label
      className={`inline-flex items-center cursor-pointer mx-4 ${className ?? ""}`}
    >
      <span className={"ms-3 text-sm font-semibold text-black"}>{label}</span>
      <input
        type={"checkbox"}
        className={"mx-2 sr-only peer"}
        checked={checked}
        onChange={(e) => {
          onChange(e.target.checked);
        }}
      />
      <div
        className={
          "min-w-[45px] relative mx-2 w-11 h-6 bg-gray-300 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary-blue"
        }
      ></div>
    </label>
  );
}
