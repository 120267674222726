import styles from "./tooltip.module.scss";

export default function Tooltip({
  children,
  content,
  position = "top",
}: {
  children: React.ReactNode;
  content: string;
  position?: string;
}) {
  return (
    <div className={styles["tooltip"]}>
      {children}
      <div
        className={`${styles.content} ${styles[position]} hidden absolute z-2  text-xs font-medium text-primary-dark transition-opacity duration-300`}
      >
        <div
          className={"bg-white ml-2 z-10 rounded-lg w-fit shadow-md px-3 py-2"}
        >
          {content}
        </div>
      </div>
    </div>
  );
}
