import { singleItem } from "@/types/global";
import { BLACK_ICON_DOWN } from "@/utils/imageRelativePaths";
import Image from "next/image";

export default function DropMenu({
  title,
  options = [],
}: {
  title: string;
  options: singleItem[];
}) {
  return (
    <div className={"relative cursor-pointer"}>
      <div className="select-none justify-evenly flex items-center rounded-lg bg-[#939FB81F]  h-[48px] lg:py-3 lg:px-6 px-2 text-center text-[#51586E] align-middle lg:text-base text-xs font-bold transition-all hover:shadow-md hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none">
        {title}
        <Image
          src={BLACK_ICON_DOWN}
          alt="icon"
          width={12}
          height={12}
          className={"ml-2 w-[12px] h-[12px] md:w-[20px] md:h-[20px]"}
        />
      </div>
      {options.length > 0 ? (
        <ul
          role="menu"
          data-popover="menu"
          data-popover-placement="bottom"
          className="absolute z-10 min-w-[180px] overflow-auto rounded-md border border-blue-gray-50 bg-white p-3 font-sans text-sm font-normal text-blue-gray-500 shadow-lg shadow-blue-gray-500/10 focus:outline-none"
        >
          {options.map((item, index) => (
            <li
              role="menuitem"
              key={item.key}
              className="block w-full cursor-pointer select-none rounded-md px-3 pt-[9px] pb-2 text-start leading-tight transition-all hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900"
            >
              {item.label}
            </li>
          ))}
        </ul>
      ) : (
        ""
      )}
    </div>
  );
}
